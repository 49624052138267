<template>
  <div class="table__row" :class="{'table__row--dec': !info.isActive}">
    <div class="container">
      <div class="table__row-inner">
        <div class="table__cell">
          <el-popover v-model="visible" placement="bottom-start">
            <span v-if="!!info.isActive">Deactivate </span>
            <span v-else>Activate </span>
            <span>{{ info.name }}?</span>
            <div style="display: flex; justify-content: space-between; margin-top: 12px">
              <el-button size="mini" type="plain" @click="visible = false">cancel</el-button>
              <el-button type="primary" size="mini" @click="changeStatus">confirm</el-button>
            </div>
            <el-switch slot="reference" :value="info.isActive" />
          </el-popover>
          <!-- <toggle-button color="#0290FC" :width="40" :sync="true" :value="!!info.isActive" @change="toggleModal(info)" /> -->
        </div>
        <div class="table__cell">
          <div class="table__cell-title">{{ info.name }}</div>
          <div class="table__cell-subtitle">{{ info.email }}</div>
        </div>
        <div class="table__cell">
          <div class="table__cell-title">{{ info.phone }}</div>
        </div>
        <div class="table__cell table__cell--horizontal">
          <span v-for="shift in info.shifts" :key="shift.id" class="table__cell-title">
            <span class="table__cell--round" :style="{backgroundColor: shift.color}"></span>
            {{ shift.name }}
          </span>
        </div>
        <div class="table__cell table__cell--end">
          <el-dropdown trigger="click" @command="action">
            <i class="ri-more-fill"></i>
            <el-dropdown-menu slot="dropdown" class="el-popper-custom el-popper--actions">
              <el-dropdown-item class="item--edit" command="Edit">
                <i class="ri-pencil-fill"></i> Edit
              </el-dropdown-item>
              <el-dropdown-item class="item--remove" command="Remove">
                <i class="ri-delete-bin-7-fill"></i> Remove
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!-- <AddDriver :dialog.sync="dialog" :data="info" /> -->
      </div>
      <div class="table__row-card card">
        <div class="card__header">
          <div class="card__box">
            <div class="letter">{{ info.name[0] }}</div>
          </div>
          <div class="card__box">
            <div class="card__title">{{ info.name }}</div>
            <div class="card__subtitle">{{ info.email }}</div>
          </div>
          <div class="card__box" @click.stop>
            <el-dropdown trigger="click" @command="action">
              <i class="ri-more-fill"></i>
              <el-dropdown-menu slot="dropdown" class="el-popper-custom el-popper--actions">
                <el-dropdown-item class="item--edit">
                  <a v-if="info.phone" class="card__link" :href="'tel:' + info.phone">
                    <i class="ri-phone-fill"></i>Call
                  </a>
                </el-dropdown-item>
                <el-dropdown-item class="item--edit" command="Edit">
                  <i class="ri-pencil-fill"></i> Edit
                </el-dropdown-item>
                <el-dropdown-item class="item--remove" command="Remove">
                  <i class="ri-delete-bin-7-fill"></i> Remove
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="card__body">
          <div class="card__line">
            <div v-for="shift in info.shifts" :key="shift.id" class="button">
              <span class="card__round" :style="{backgroundColor: shift.color}"></span>
              {{ shift.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ToggleButton} from 'vue-js-toggle-button'
import Dropdown from '@/components/Dropdown'
import Modal from '@/components/ToggleModal'
import AddDriver from '@/views/Admin/Drivers/AddDriver/index.vue'

export default {
  name: 'Driver',
  components: {
    AddDriver,
    ToggleButton,
    Dropdown
  },
  props: {
    info: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    value: 'dropdown',
    options: ['Edit', 'Remove'],
    visible: false,
    dialog: false
  }),
  methods: {
    async action(act) {
      if (act === 'Edit') {
        // this.dialog = true
        this.$root.$emit('ShowSidebar', {
          sidebarName: 'AddDriver',
          componentData: this.info
        })
      } else if (act === 'Remove') {
        try {
          await this.$store.dispatch('drivers/delete', this.info.id)
          this.$store.commit('drivers/change', this.info.id)
        } catch (e) {}
      }
    },
    toggleModal(info) {
      const style = this.$modal.styles
      this.$modal.show(
        Modal,
        {info, type: 'drivers'},
        {...style, name: 'ToggleModal', height: '140px'},
        {
          'before-open': this.$modal.open,
          'before-close': this.$modal.close
        }
      )
    },
    async changeStatus() {
      try {
        if (this.info.isActive) {
          await this.$store.dispatch(`drivers/deactivate`, this.info.id)
        } else {
          await this.$store.dispatch(`drivers/activate`, this.info.id)
        }
        this.info.isActive = !this.info.isActive
        this.$store.commit(`drivers/change`, this.info)
        this.visible = false
        // this.$modal.hide('ToggleModal')
      } catch (e) {
        alert(e.response.data.error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
.wr {
  position: absolute;
  bottom: 0;
  right: 0;
  border: 1px solid red;
}
.table__row {
  position: relative;
}
</style>
