var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "drivers__table table" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "table__main" },
          _vm._l(_vm.drivers, function(driver) {
            return _c("Driver", { key: driver.id, attrs: { info: driver } })
          }),
          1
        )
      ]),
      !_vm.isLoading && _vm.hasMoreResults
        ? _c("infinite-loading", { on: { infinite: _vm.infLoad } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "table__title-wrapper" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "table__title-inner" }, [
          _c("div", { staticClass: "table__title-item" }, [_vm._v("On/Off")]),
          _c("div", { staticClass: "table__title-item" }, [
            _vm._v("User Name")
          ]),
          _c("div", { staticClass: "table__title-item" }, [
            _vm._v("Phone number")
          ]),
          _c("div", { staticClass: "table__title-item" }, [
            _vm._v("Available Shifts")
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }