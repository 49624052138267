<template>
  <div>
    <div class="drivers__table table">
      <div class="table__title-wrapper">
        <div class="container">
          <div class="table__title-inner">
            <div class="table__title-item">On/Off</div>
            <div class="table__title-item">User Name</div>
            <div class="table__title-item">Phone number</div>
            <div class="table__title-item">Available Shifts</div>
          </div>
        </div>
      </div>
      <div class="table__main">
        <Driver v-for="driver in drivers" :key="driver.id" :info="driver" />
      </div>
    </div>
    <infinite-loading v-if="!isLoading && hasMoreResults" @infinite="infLoad"></infinite-loading>
  </div>
</template>

<script>
import Driver from '@/views/Admin/Drivers/Driver'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: 'Drivers',
  components: {Driver, InfiniteLoading},
  data() {
    return {
      isLoading: false,
      dialog: false
    }
  },
  computed: {
    drivers() {
      return this.$store.getters['drivers/drivers']
    },
    hasMoreResults() {
      return this.drivers.length < this.totalCount && this.page > 1
    },
    totalCount() {
      return this.$store.getters['drivers/pagination'].total
    },
    page() {
      return this.$store.getters['drivers/page']
    }
  },
  created() {
    if (!this.$store.getters['drivers/pagination'].total) {
      this.$store.dispatch('drivers/fetch')
    }
  },
  methods: {
    showAddDriver() {
      this.$root.$emit('ShowSidebar', {
        sidebarName: 'AddDriver',
        sidebarStyles: 'width: 700px'
      })
    },
    async infLoad() {
      this.isLoading = true
      try {
        await this.$store.dispatch('drivers/fetch')
        this.$emit('loaded')
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
